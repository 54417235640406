import {
  Component,
  ContentChild,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollingModule
} from '@angular/cdk/scrolling';
import {CommonModule, JsonPipe} from '@angular/common';
import {
  MatExpansionModule,
} from '@angular/material/expansion';
import {BehaviorSubject, debounceTime} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {CloseableComponent} from '../closeable/closeable.component';
import {FilterIndicatorComponent} from '../../../modules/routing/register/filter-indicator/filter-indicator.component';
import {MatIcon} from '@angular/material/icon';
import {QuickFilterService} from '../../../core/services/quick-filter.service';

@Component({
  selector: 'ostso-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    JsonPipe,
    MatExpansionModule,
    ReactiveFormsModule,
    ScrollingModule,
    CloseableComponent,
    FilterIndicatorComponent,
    MatIcon,
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ]
})
export class MultiSelectComponent<T extends Record<string, any>, > implements OnInit, OnDestroy {

  _value: T[] = [];
  _data: T[] = [];
  // selectedItems: T[] = [];
  readonly panelOpenState = signal(false);

  @Input() set data(arr: T[]) {
    this._data = arr;
    this.filteredItems$.next(this._data);
  }

  @Input() placeholder: string = '';
  @Input() selectField?: string = 'name';
  @ContentChild(TemplateRef) contentItem: TemplateRef<any> | null = null;

  search = new FormControl('');

  filteredItems$: BehaviorSubject<T[]> = new BehaviorSubject([] as T[]);

  get value(): T[] {
    return this._value;
  }

  set value(value: T[]) {
    if (Array.isArray(value)) {
      this._value = value;
      this.onChange(value);
    } else {
      this._value = [value];
      this.onChange([value]);
    }
  }

  constructor(private ngZone: NgZone, private quickFilter: QuickFilterService,) {
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };


  writeValue(value: T[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Реализуйте логику отключения, если необходимо
  }

  private _filter(value: string): void {
    // if(!value || value.length === 0) return;
    this.quickFilter.getData(this._data, value).then((data: any) => this.filteredItems$.next(data));
  }

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(
        debounceTime(300))
      .subscribe(v => {
        this._filter(v || '');
      });
  }

  onInputClick($event: MouseEvent): void {
    $event.stopPropagation();
  }

  onKey($event: KeyboardEvent): void {
    $event.stopPropagation();
  }

  removeItem(item: T, $event: MouseEvent): void {
    $event.stopPropagation();
    this.value = this.value.filter(i => i['id'] !== item['id']);
  }

  addItem(item: T): void {

    this.value = [...new Set([...this.value, item])];
  }

  clearSearch($event: MouseEvent): void {
    $event.stopPropagation();
    this.search.setValue('');
  }

  ngOnDestroy(): void {
    console.log('destroy');
  }
}

