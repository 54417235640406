import {Component, Input} from '@angular/core';

@Component({
  selector: 'ostso-filter-indicator',
  standalone: true,
  imports: [],
  templateUrl: './filter-indicator.component.html',
  styleUrl: './filter-indicator.component.scss'
})
export class FilterIndicatorComponent {
@Input() count?: number;
@Input() label: string='';
}
