<mat-accordion>
  <mat-expansion-panel appearance="outline" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)"
                       class="ostso-multi-select">
    <mat-expansion-panel-header>
      <div
        class="ostso-multi-select__panel-header mat-mdc-select mat-mdc-select-placeholder mat-mdc-select-min-line ng-star-inserted"
      >
        <div class="ostso-multi-select__placeholder" [class.shifted]="value.length > 0">{{ placeholder }}</div>
        <div class="ostso-multi-select__list">
          @if (value && value.length > 0) {
            @for (selected of value; track selected['id']) {
              <div class="ostso-multi-select__badge" (click)="removeItem(selected, $event)">
                <span>{{ selected[selectField || 'name'] }}</span>
                <mat-icon class="ostso-multi-select__icon">close</mat-icon>
              </div>
            }
          }
        </div>
      </div>
    </mat-expansion-panel-header>
    <cdk-virtual-scroll-viewport itemSize="30" class="ostso-multi-select__viewport">
      <div class="ostso-multi-select__description">
        <input type="text" [formControl]="search" (keydown)="onKey($event)" [placeholder]="placeholder">
        <mat-icon class="ostso-multi-select__close" (click)="clearSearch($event)">close</mat-icon>
      </div>
      <div *cdkVirtualFor="let item of filteredItems$.value"
           class="ostso-multi-select__item mat-mdc-option mdc-list-item" (click)="addItem(item)">
        <ng-container class="ostso-multi-select__item-content"
                      *ngTemplateOutlet="contentItem; context:{item}"></ng-container>
        <button class="ostso-multi-select__add">
          <mat-icon class="ostso-multi-select__icon">add</mat-icon>
        </button>
      </div>
    </cdk-virtual-scroll-viewport>
  </mat-expansion-panel>
</mat-accordion>


