import {Component, EventEmitter, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'ostso-closeable',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './closeable.component.html',
  styleUrl: './closeable.component.scss'
})
export class CloseableComponent {
@Output() shut = new EventEmitter();

  onClick($event: MouseEvent):void{
    $event.stopPropagation();
    this.shut.emit();
  }
}
